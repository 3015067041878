import "./App.css";
import { usePrivy, useWallets } from "@privy-io/react-auth";
import {
  PrimeSdk,
  isWalletProvider,
  Web3eip1193WalletProvider,
  EtherspotBundler
} from "@etherspot/prime-sdk";
import { useState, Fragment } from "react";
import { ethers, BigNumber } from "ethers";
import { Dialog, Transition, Listbox, Switch } from "@headlessui/react";
import BeatLoader from "react-spinners/BeatLoader";
import { faShuffle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// NFTs
const NFTList = [
  { name: "Etherspot Logo NFT" },
  { name: "4337 NFT" },
  { name: "PillarX NFT" },
];

function App() {
  // Privy login
  const { ready, authenticated, user, login, logout } = usePrivy();
  const { wallets } = useWallets();

  // Etherspot details
  const [providerWallet, setProviderWallet] = useState("");
  const [etherspotLoggedIn, setEtherspotLoggedIn] = useState(false);

  // Transaction details
  const [address, setAddress] = useState("");
  const [transactionArray, setTransactionArray] = useState([]);
  const [transactionHash, setTransactionHash] = useState([]);
  const [gasPriceMatic, setGasPriceMatic] = useState([]);
  const [transacted, setTransacted] = useState(false);
  const [transactionHistory, setTransactionHistory] = useState([]);

  // UI
  const [introduction, setIntroduction] = useState(false);
  const [privyFlow, setPrivyFlow] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [tableInitialised, setTableInitialised] = useState(false);
  const [whitelist, setWhitelist] = useState(false);
  const [addedUserOpsToBatch, setAddedUserOpsToBatch] = useState(false);
  const [estimation, setEstimation] = useState(false);
  const [signedAndSent, setSignedAndSent] = useState(false);
  const [transactionComplete, setTransactionComplete] = useState(false);
  const [selectedNFT, setSelectedNFT] = useState(NFTList[0]);
  const [selectedNFT1, setSelectedNFT1] = useState(false);
  const [selectedNFT2, setSelectedNFT2] = useState(false);
  const [selectedNFT3, setSelectedNFT3] = useState(false);

  // Wait until the Privy client is ready before taking any actions
  if (!ready) {
    return null;
  }

  const privyLogin = async () => {
    setIntroduction(true);
    setEtherspotLoggedIn(true);
    await login();
  };

  const privyLogout = async () => {
    setIntroduction(false);
    setEtherspotLoggedIn(false);
    await logout();
  };

  const generateRandomWallet = async () => {
    const randomWallet = ethers.Wallet.createRandom();
    const providerWallet = new ethers.Wallet(randomWallet.privateKey);
    setProviderWallet(providerWallet);
    setEtherspotLoggedIn(true);
    setIntroduction(true);
  };

  function closeModal() {
    setTransactionArray([]);
    setWhitelist(false);
    setEstimation(false);
    setAddedUserOpsToBatch(false);
    setSignedAndSent(false);
    setTransactionComplete(false);
    setIsOpen(false);
    setTableInitialised(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  function closeIntroduction() {
    setEtherspotLoggedIn(true);
    setPrivyFlow(true);
    setIntroduction(false);
  }

  const mintNFTs = async () => {
    // Get SDK
    let primeSdk;
    const bundlerApiKey = 'eyJvcmciOiI2NTIzZjY5MzUwOTBmNzAwMDFiYjJkZWIiLCJpZCI6IjMxMDZiOGY2NTRhZTRhZTM4MGVjYjJiN2Q2NDMzMjM4IiwiaCI6Im11cm11cjEyOCJ9';

    if (ready && authenticated) {
      const privyProvider = await wallets[0].getWeb3jsProvider();
      const provider = privyProvider.walletProvider;

      let mappedProvider;
      if (!isWalletProvider(provider)) {
        try {
          // @ts-ignore
          mappedProvider = new Web3eip1193WalletProvider(provider);
          await mappedProvider.refresh();
        } catch (e) {
          // no need to log, this is an attempt
        }

        if (!mappedProvider) {
          throw new Error("Invalid provider!");
        }
      }
      primeSdk = new PrimeSdk(mappedProvider ?? provider,
        { chainId: 84532, bundlerProvider: new EtherspotBundler(84532, bundlerApiKey)  
        });
        
      } else {
        primeSdk = new PrimeSdk(providerWallet,
        { 
          chainId: 84532, bundlerProvider: new EtherspotBundler(84532, bundlerApiKey)  
        });
      }

    openModal();

    // Whitelist address
    const address = [await primeSdk.getCounterFactualAddress()];
    const api_key = "arka_public_key";
    const chainId = 84532;
    const returnedValue = await fetch("https://arka.etherspot.io/whitelist", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ params: [address, chainId, api_key] }),
    })
      .then((res) => {
        return res.json();
      })
      .catch((err) => {
        console.log(err);
      });

    console.log("Value returned: ", returnedValue);

    setWhitelist(true);

    // clear the transaction batch
    await primeSdk.clearUserOpsFromBatch();

    // get contract interface
    const erc721Interface = new ethers.utils.Interface([
      "function mint(address _to)",
    ]);

    // Add all transactions to the batch
    for (let i = 0; i < transactionArray.length; i++) {
      const erc721Data = erc721Interface.encodeFunctionData("mint", [
        transactionArray[i][0],
      ]);
      await primeSdk.addUserOpsToBatch({
        to: transactionArray[i][1].toString(),
        data: erc721Data,
      });
    }

    setAddedUserOpsToBatch(true);

    // estimate
    const estimation = await primeSdk.estimate({
      paymasterDetails: {
        url: `https://arka.etherspot.io?apiKey=arka_public_key&chainId=84532`,
        context: { mode: "sponsor" },
      },
    });

    setEstimation(true);

    // Get gas cost
    const totalGas = await primeSdk.totalGasEstimated(estimation);
    const gas = totalGas.mul(BigNumber.from(estimation.maxFeePerGas));
    const gasInMatic = ethers.utils.formatEther(gas);

    console.log("ESTIMATION:", estimation)
    console.log("GAS: ", totalGas, gas, gasInMatic)
    setGasPriceMatic(gasInMatic);

    // sign the UserOp and send to the bundler
    const uoHash = await primeSdk.send(estimation);
    console.log(`UserOpHash: ${uoHash}`);

    setSignedAndSent(true);

    // get transaction hash...
    console.log("Waiting for transaction...");
    let userOpsReceipt = null;
    const timeout = Date.now() + 60000; // 1 minute timeout
    while (userOpsReceipt == null && Date.now() < timeout) {
      userOpsReceipt = await primeSdk.getUserOpReceipt(uoHash);
    }
    console.log("\x1b[33m%s\x1b[0m", `Transaction Receipt: `, userOpsReceipt);

    setTransactionHash(userOpsReceipt.receipt.transactionHash);
    setTransactionComplete(true);
    let transactionHistoryArray = transactionHistory;
    setTransactionHistory(transactionHistoryArray.push(transactionArray))
    console.log("txn history: ",transactionHistory)
    setTransacted(true);
  };

  const addMultipleTransactionsToBatch = async () => {
    if (address != "" && (selectedNFT1 || selectedNFT2 || selectedNFT3 == true)) {
      let transactionBatch = transactionArray;
      let contractAddress;
      let functionCall;

      if (selectedNFT1) {
        contractAddress = "0x7010F7Ac55A64Ca6b48CDC7C680b1fb588dF439f";
        functionCall = "mintEtherspotNFT";
        transactionBatch.push([
          address,
          contractAddress,
          "",
          functionCall,
        ]);
      } 
      
      if (selectedNFT2) {
        contractAddress = "0x6d949893f8CF224De19190eDaEc610Bc58E2c4fC";
        functionCall = "mint4337NFT";
        transactionBatch.push([
          address,
          contractAddress,
          "",
          functionCall,
        ]);
      } 

      if (selectedNFT3) {
        contractAddress = "0x42D9E60951b11d1Ace9280aDc13D86078949C248";
        functionCall = "mintPillarXNFT";
        transactionBatch.push([
          address,
          contractAddress,
          "",
          functionCall,
        ]);
      }

      setTransactionArray(transactionBatch);
      setAddress("");
      setSelectedNFT1(false);
      setSelectedNFT2(false);
      setSelectedNFT3(false);
      setTableInitialised(true);
    } else {
      return;
    }
  };

  const setRandomAddress = async () => {
    const randomWallet = ethers.Wallet.createRandom();
    setAddress(randomWallet.address);
  };

  const clearBatch = async () => {
    setTableInitialised(false);
    setTransactionArray([]);
  };

  return (
    <div className="App">
      <header className="App-header">
        <img
          src="/etherspot.png"
          width="100"
          height="150"
        ></img>
        <strong className="titleText">Etherspot Demo </strong>
      </header>
      <div className="App-main">
        <div>
          {(() => {
            // Login flow for Privy or random wallet
            if (!etherspotLoggedIn && !authenticated) {
              return (
                <div>
                  <button
                    class="bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded"
                    onClick={privyLogin}
                  >
                    Log In with Privy
                  </button>
                  <br />
                  <br />
                  <button
                    class="bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded"
                    onClick={generateRandomWallet}
                  >
                    Generate a random test wallet
                  </button>
                </div>
              );
            }
          })()}
        </div>

        {(introduction && etherspotLoggedIn) ||
        (authenticated && !privyFlow) ? (
          <div>
            <strong>
              In this demo, we'll take a brief look at what you can do with
              Etherspot.
            </strong>
            <br />
            <br />
            <strong>
              We've already logged in, and in the background an Etherspot smart
              contract account has been created.
            </strong>
            <br />
            <br />
            <strong>
              In the following screen, you can select whichever NFTs you want to mint, and choose
              which address you want them minted to.
            </strong>
            <br/>
            <br/>
            <strong>
              We'll batch all these transactions together, send the transactions, and
              have the gas paid for by{" "}
              <a
                class="hover:underline"
                href="https://etherspot.fyi/arka/intro"
                target="_blank"
              >
                Arka.
              </a>{" "}
            </strong>
            <strong></strong>
            <br />
            <br />
            <button
              class="bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded"
              onClick={closeIntroduction}
            >
              OK!
            </button>
          </div>
        ) : (
          <p></p>
        )}

        {etherspotLoggedIn && !introduction ? (
          <div>
              <strong> Select multiple NFTs to mint</strong>

            
            <div className="App-checkboxes2 mb-4">
            <div className="App-checkboxes mr-3">
              <img className="mb-3 my-3"
                src="/etherspot.png"
                width="30"
                height="50"
              ></img>
              <img className="mb-2"
                src="/4337.png"
                width="80"
                height="50"
              ></img>
              <img className="mb-3"
                src="/PillarX.png"
                width="40"
                height="60"
              ></img>
            </div>
              <div className="App-checkboxes">
              <strong>Etherspot NFT</strong>
              <strong className="mt-3">4337 NFT</strong>
              <strong className="mt-3">PillarX NFT</strong>
            </div>
              <div className="App-checkboxes">
                <Switch 
                  checked={selectedNFT1}
                  onChange={setSelectedNFT1}
                  className={`${selectedNFT1 ? 'bg-green-700' : 'bg-red-700'}
                    relative inline-flex h-[21px] w-[45px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
                >
                  <span className="sr-only">Etherspot NFT</span>
                  <span
                    aria-hidden="true"
                    className={`${selectedNFT1 ? 'translate-x-6' : 'translate-x-0'}
                      pointer-events-none  h-[17px] w-[17px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                  />
                </Switch>
              <Switch
                checked={selectedNFT2}
                onChange={setSelectedNFT2}
                className={`${selectedNFT2 ? 'bg-green-700' : 'bg-red-700'}
                  my-6 mx-5 relative inline-flex h-[21px] w-[45px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
              >
                <span className="sr-only">Etherspot NFT</span>
                <span
                  aria-hidden="true"
                  className={`${selectedNFT2 ? 'translate-x-6' : 'translate-x-0'}
                    pointer-events-none inline-block h-[17px] w-[17px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                />
              </Switch>

              <Switch
                checked={selectedNFT3}
                onChange={setSelectedNFT3}
                className={`${selectedNFT3 ? 'bg-green-700' : 'bg-red-700'}
                  relative inline-flex h-[21px] w-[45px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
              >
                <span className="sr-only">Etherspot NFT</span>
                <span
                  aria-hidden="true"
                  className={`${selectedNFT3 ? 'translate-x-6' : 'translate-x-0'}
                    pointer-events-none inline-block h-[17px] w-[17px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                />
              </Switch>
              </div>

            </div>
            <strong className="mx-8"> Set an address to mint the NFTs to</strong>
            <div className="App-input-address">
              <input
                class="mt-1 shadow appearance-none border rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-align: center;"
                type="text"
                value={address}
                onChange={(event) => setAddress(event.target.value)}
              />
              <div
                onClick={setRandomAddress}
                className="flex items-center justify-center mr-2 text-orange-500 App-icon"
              >
                <FontAwesomeIcon icon={faShuffle} />
              </div>
            </div>

            <button
              class="bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded-lg my-3"
              onClick={addMultipleTransactionsToBatch}
            >
              Add transactions to batch
            </button>

            {tableInitialised ? (
              <div className="App-table">
                <strong>Transaction Batch</strong>
                <table className="table-fixed">
                  <tr>
                    <th>Function call</th>
                    <th>Contract Address</th>
                    <th>Receiver</th>
                  </tr>
                  {transactionArray.map((val, key) => {
                    return (
                      <tr key={key}>
                        <td>{val[3]}</td>
                        <td>{val[1].substring(0, 10)}...</td>
                        <td>{val[0].substring(0, 10)}...</td>
                      </tr>
                    );
                  })}
                </table>
                <div className="App-mint">
                  <div className="clearButton">
                    <button
                      class="bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded"
                      onClick={clearBatch}
                    >
                      Clear Batch
                    </button>
                  </div>
                  <div className="mintButton">
                    <button
                      class="bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded"
                      onClick={mintNFTs}
                    >
                      Mint NFTs
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div></div>
            )}
          </div>
        ) : (
          <div></div>
        )}

        {ready && authenticated ? (
          <div>
            <br />
            <button
              class="bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded-lg"
              onClick={privyLogout}
            >
              Log Out of Privy
            </button>
          </div>
        ) : (
          <div></div>
        )}

        <Transition appear show={isOpen} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={closeModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black/25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Transaction Processing:
                    </Dialog.Title>

                    {!whitelist ? (
                      <div>
                        <p>
                          Whitelisting Etherspot address on Arka paymaster...
                        </p>
                        <BeatLoader />
                      </div>
                    ) : (
                      <p>✅ Arka Whitelist successful</p>
                    )}

                    {!addedUserOpsToBatch ? (
                      <div>
                        <p>Creating UserOps...</p>
                        <BeatLoader />
                      </div>
                    ) : (
                      <p>✅ UserOps created and added to the batch</p>
                    )}

                    {!estimation ? (
                      <div>
                        <p>Estimating gas...</p>
                        <BeatLoader />
                      </div>
                    ) : (
                      <p>
                        ✅ Gas estimated correctly and will be paid for by Arka
                      </p>
                    )}

                    {!signedAndSent ? (
                      <div>
                        <p>Signing transaction and sending to Skandha...</p>
                        <BeatLoader />
                      </div>
                    ) : (
                      <p>✅ Transaction signed and sent to Skandha Bundler</p>
                    )}
                    {!transactionComplete ? (
                      <div>
                        <p>Finalising transaction...</p>
                        <BeatLoader />
                      </div>
                    ) : (
                      <div>
                        <p>
                          ✅&nbsp;
                          <a
                            class="underline"
                            href={`https://sepolia.basescan.org/tx/${transactionHash}`}
                            target="_blank"
                          >
                            Transaction included on-chain, click here to view it🔗
                          </a>
                          <br />
                          <br />
                          <p>
                            A total of {transactionArray.length} transactions
                            were batched together which cost{" "}
                            {gasPriceMatic.substring(0, 9)} in ETH, paid for
                            by our paymaster, Arka!
                          </p>
                        </p>
                      </div>
                    )}

                    <div className="mt-4">
                      <button
                        type="button"
                        className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                        onClick={closeModal}
                      >
                        Close
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </div>
      <div className="App-footer"></div>
    </div>
  );
}

export default App;
